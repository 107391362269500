import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  IonToast,
  setupIonicReact
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Main from './pages/Main';
import Device from './pages/Device';
import Settings from './pages/Settings';
import ModuleDetail from "./pages/ModuleDetail";
import AppSettings from "./pages/AppSettings";
import OtaUpload from "./pages/OtaUpload";
import Uart from "./pages/Uart";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Redux store
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {initLogStore} from "./store/logSlice";
import {useFakeData} from "./store/fakeData";
import {displayFakeDataOnly, fakeDataFromStart, fakeModuleValueUpdate, appVersion} from "./config";


setupIonicReact({
  hardwareBackButton  : false,
  swipeBackEnabled    : false,
  mode                : "md"
});



const App: React.FC = () => {
  const {isToastShown, toastMsg} = useAppSelector(state => state.log);
  const dispatch = useAppDispatch();
  const {hydrateFakeData, updateModuleValues} = useFakeData();

  useEffect(() => {
    console.log(`[App] init, version ${appVersion}`);
    dispatch(initLogStore());

    if (displayFakeDataOnly) {
      if (fakeDataFromStart) {
        hydrateFakeData();
      }
      if (fakeModuleValueUpdate) {
        updateModuleValues();
      }
      
    }
  }, []);

  return (
    <IonApp >
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/" exact>
              <Redirect to="/main"/>
            </Route>
            <Route path="/main" exact>
              <Main />
            </Route>
            <Route path="/device" exact>
              <Device />
            </Route>
            <Route path="/detail" exact>
              <ModuleDetail />
            </Route>
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="/ota-upload" exact>
              <OtaUpload />
            </Route>
            <Route path="/uart" exact>
              <Uart />
            </Route>
            <Route path="/app-settings" exact>
              <AppSettings />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
        <IonToast
          isOpen={isToastShown}
          message={toastMsg}
        />

    </IonApp>
)};

export default App;
