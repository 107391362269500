import React from 'react';
import {IonButton, IonIcon} from "@ionic/react";
import {arrowBackSharp} from "ionicons/icons";
import {useHistory} from "react-router-dom";

const BackButton: React.FC = () => {
  let history = useHistory();

  return (
    <IonButton onClick={() => history.goBack()}
               aria-label="back"
    >
      <IonIcon icon={arrowBackSharp}
               size="default"
               slot="icon-only"
               aria-label="back" />
    </IonButton>
  );
};

export default BackButton;
