import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {useHistory} from "react-router-dom";
import {
  IonButton,
  IonButtons, IonCol,
  IonContent, IonGrid,
  IonHeader, IonInput, IonItem, IonLabel, IonList,
  IonPage, IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter
} from "@ionic/react";

import {
  getCurrentPeripheral,
  getCurrentUartLog,
  isThereConnectedPeripheral,
  uartLogMsg
} from "../store/peripheralsSlice";

import {useHwBack} from "../hooks/useHwBack";
import {useBle} from "../hooks/useBle";
import {useLog} from "../hooks/useLog";
import {log} from "../utils/utils";
import {toast} from "../store/logSlice";
import {UartLogMsg} from "../types";

import './Uart.css';
import BackButton from "../components/BackButton";

const Uart: React.FC = () => {
  const logTag = "Uart";
  const {logInfo, logError} = useLog(logTag);

  let history = useHistory();
  const isConnected = useAppSelector(isThereConnectedPeripheral);

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the HW back button
  useHwBack(logTag, null, []);

  // const isConnected = useAppSelector(isThereConnectedPeripheral);
  const peripheral = useAppSelector(getCurrentPeripheral);
  const uartLog = useAppSelector(getCurrentUartLog);
  const dispatch = useAppDispatch();
  const {startUartNotification, writeUartCommand} = useBle();
  const [valueToWrite, setValueToWrite] = useState<string>("");


  // register for UART notification on page enter. The method takes care of multiple subscriptions
  // @note peripheral as dependency - for cases when user refreshes while in Uart page
  useIonViewWillEnter(() => {
    log.log(logTag, `page entered`);
    startUartNotification(peripheral);
  }, [peripheral]);

  const getContent = () => {
    return document.querySelector('ion-content');
  }

  const sendCommand = async () => {
    // add this message to log that can be shown to user
    dispatch(uartLogMsg("central", valueToWrite));
    getContent()?.scrollToTop(500);

    try {
      // transform the value to bytes and send them over BLE to the peripheral
      await writeUartCommand(peripheral.id, valueToWrite);
      logInfo(`Write (with response) of UART command to ${peripheral.id} was successful: ${valueToWrite}`);
      dispatch(toast("UART command sent successfully"));
      setValueToWrite("");

    } catch (error) {
      dispatch(toast(`Error while writing UART command.`));
      logError(`Error while writing UART command.`, false, { command : valueToWrite, error });

      // display the error in UART log as well
      if (error instanceof Error && error.message) {
        dispatch(uartLogMsg("peripheral", error.message));
      }
    }
  };


  return (
    <IonPage id="app-uart">
      <IonHeader className="app-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>

          <IonTitle>Service</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="app-layout">
        { // @note: we're using pinEntered, not isPinRequired, because it's possible Settings haven't been
          // read yet
        !peripheral.pinEntered ? 
        (<p className="ion-padding-horizontal">
          Note: if the device requires a PIN code, you will have to enter it in Settings before you can use
          this service.
        </p>) : ""} 

        <IonItem>
          <IonInput label=""
                    labelPlacement="stacked"
                    clearInput={true}
                    type="text"
                    inputMode="text"
                    placeholder="Type a command"
                    value={valueToWrite}
                    onIonInput={(e) => setValueToWrite(`${e.detail.value}` || "")}
          ></IonInput>

          <IonButton color="main"
                     disabled={!valueToWrite}
                     onClick={sendCommand}
          >Send</IonButton>
        </IonItem>

        <IonList className="">
        {(uartLog && uartLog.length > 0) ? ([...uartLog].reverse().map((logMsg : UartLogMsg, index) => (
          <IonItem key={index} className="app-uart-logs-item">

            <IonGrid className="ion-no-padding">
              <IonRow className="ion-justify-content-center ion-align-items-center">
                <IonCol size="3" className="ion-text-center ion-padding-end">
                  <div>{new Date(logMsg.timestamp!).toLocaleTimeString()}</div>
                  <div>{logMsg.type === "peripheral" ? "Rx" : "Tx"}</div>
                </IonCol>
                <IonCol size="9" className="ion-padding-vertical ion-padding-start">
                  <IonLabel className="ion-text-wrap app-uart-msg"
                            color={logMsg.type === "peripheral" ? "primary" : ""}
                  >{logMsg.msg}</IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>

          </IonItem>
        ))) : ""}
        </IonList>

      </IonContent>
    </IonPage>
  );
};

export default Uart;
