// #######################################
// ### Detecting platforms
// #######################################
// @note: if this doesn't work well, there's always:
// import {isPlatform} from @ionic/react
import {Capacitor} from '@capacitor/core';
import {DeviceInfo} from "@capacitor/device";

export const isWeb = () => Capacitor.getPlatform() === "web";
export const isNativeApp = () => Capacitor.isNativePlatform();
export const isAndroidNativeApp = () => Capacitor.getPlatform() === "android";
export const isIosNativeApp = () => Capacitor.getPlatform() === "ios";

// storing device info in a global / singleton fashion because we need android SDK version
let deviceInfo : DeviceInfo;
export const setDeviceInfo = (info : DeviceInfo) => deviceInfo = info;
export const androidSDKVersion = () : number => deviceInfo?.androidSDKVersion || 1;