import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";

export interface WriteProgressState {
  writeInProgress : boolean,
  writeFinished   : boolean,
  writeProgress   : number,
  writeTime       : number
}

let initialState: WriteProgressState = {
  writeInProgress : false,
  writeFinished   : false,
  writeProgress   : 0,
  writeTime       : 0
};

const writeProgressSlice = createSlice({
  name: 'writeProgress',

  initialState,

  reducers: {
    setWriteInProgress(state, action : PayloadAction<boolean>) {
      state.writeInProgress = action.payload;
    },

    setWriteFinished(state, action : PayloadAction<boolean>) {
      state.writeFinished = action.payload;
    },

    setWriteProgress(state, action : PayloadAction<number>) {
      state.writeProgress = action.payload;
    },

    setWriteTime(state, action : PayloadAction<number>) {
      state.writeTime = action.payload;
    },

    resetWriteProgress(state) {
      state.writeInProgress = false;
      state.writeFinished = false;
      state.writeProgress = 0;
      state.writeTime = 0;
    }
  }
});


// ##### Selectors
export const getWriteInProgress = (state : RootState) => state.writeProgress.writeInProgress;
export const getWriteFinished = (state : RootState) => state.writeProgress.writeFinished;
export const getWriteProgress = (state : RootState) => state.writeProgress.writeProgress;
export const getWriteTime = (state : RootState) => state.writeProgress.writeTime;
export const getWriteTimeSeconds = (state : RootState) => Math.round(state.writeProgress.writeTime/1000);


// #### Default exports (actions, reducer)
export const {
  setWriteInProgress,
  setWriteFinished,
  setWriteProgress,
  setWriteTime,
  resetWriteProgress
} = writeProgressSlice.actions;

export default writeProgressSlice.reducer;
