import {debounceTimeMs, verboseLogs} from "../config";
import isString from "lodash/isString";
import merge from "lodash/merge";

// #######################################
// ### merging two arrays of objects by properties - used e.g. when merging a list of peripherals
// #######################################
export const mergeByProperty =
  (target : Array<{[index: string] : any}>, source : Array<{[index: string] : any}>, prop : string)
    : Array<object> =>
  {
  // console.log("mergeByProperty");
  let newTarget = [...target];

  source.forEach(sourceElement => {
    let targetElement = newTarget.find(targetElement => {
      return sourceElement[prop] === targetElement[prop];
    });
    // @note if we use Object.assign here, manufacturerData will disappear between scan and connect
    targetElement ? merge(targetElement, sourceElement) : newTarget.push(sourceElement);
  });

  return newTarget;
};

// #######################################
// ### Logging (to console only! for other logs, check logSlice and useLog)
// #######################################
export const log = {
  // with "verbose", we can omit logs which are annoying
  log(logTag : string, thingToLog : any, verbose : boolean = false) {
    if (verbose && !verboseLogs) {
      return;
    }

    // only one parameter was provided. Treat it as the message, not only as a tag
    if (typeof thingToLog === "undefined") {
      console.log(logTag);
    } else {
      console.log(`[${logTag}]`, thingToLog);
    }
  }
};

// #######################################
// ### Stringifying and extracting messages from various error objects
// #######################################
const extractErrorMessage = (error : Error) : string => {
  let result = "";

  if (error.name && isString(error.name)) {
    result += `[${error.name}]`;
  }

  if (error.message && isString(error.message)) {
    result += ` ${error.message}`;
  }

  return result;
}

export const jsonStringify = (objectToStringify : any) : string => {
  let stringifiedObject : string;

  try {
    stringifiedObject = JSON.stringify(objectToStringify);

  } catch (jsonError) {
    console.log("Error while stringifying an object :)");
    console.log(jsonError);
    stringifiedObject = "Unable to stringify the object!";
  }

  return stringifiedObject;
}

// very specialized stringifier of mostly error objects we pass around to logs, app logs, toasts and sentry
// all over the application
export const stringifyObject = (objectToStringify : any) : {objectMsg : string, objectRest : string} => {
  if (isString(objectToStringify)) {
    return {objectMsg : objectToStringify, objectRest : ""};
  }

  // regular exceptions like DOMException, but also CapacitorException from @capacitor/core
  if (objectToStringify instanceof Error) {
    const stringifiedError = extractErrorMessage(objectToStringify);
    if (stringifiedError) {
      return {objectMsg : stringifiedError, objectRest : ""};
    }
  }

  if (objectToStringify.error instanceof Error) {
    const {error, ...rest} = objectToStringify;
    const stringifiedError = extractErrorMessage(error);
    if (stringifiedError) {
      return {objectMsg : stringifiedError, objectRest : jsonStringify(rest)};
    }
  }

  // so objectToStringify is not a pure string or an Error - let's simply try to stringify it then. We'll
  // return it as objectRest because we couldn't extract any string message
  return {objectMsg : "", objectRest : jsonStringify(objectToStringify)};
};

// #######################################
// ### Debouncer - e.g. for advertisements
// #######################################
let DebounceMap : {[index: string] : number} = {};

export const debounceById = (id : string) : boolean => {
  let now = Date.now();
  if (DebounceMap[id] && DebounceMap[id] > (now - debounceTimeMs)) {
    return false;
  } else {
    DebounceMap[id] = now;
    // console.log(`Debouncer: ${id} allowed`);
    return true;
  }
};

// #######################################
// ### waitfor - simple waiting utility
// #######################################
export const waitFor = (ms : number) => new Promise(resolve => setTimeout(resolve, ms));
