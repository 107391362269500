import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from "@sentry/react";

import store from './store/store';
import {Provider} from "react-redux";
import {appVersion, appVariant} from "./config";
import {isWeb} from "./utils/platforms";

Sentry.init({
  dsn               : "https://2cae47d84be7430b9d544293b41421d9@o181077.ingest.sentry.io/5216774",
  release           : `${appVariant}@${appVersion}`,
  integrations      : [Sentry.browserTracingIntegration()],
  tracesSampleRate  : 0.1,
  environment       : process.env.NODE_ENV
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Provider store={store}><App /></Provider>);

// PWA: change unregister() to register() below. Note this comes with some pitfalls.
// https://create-react-app.dev/docs/making-a-progressive-web-app/
// https://ionicframework.com/docs/react/pwa#making-your-react-app-a-pwa-with-create-react-app
if (isWeb()) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}
