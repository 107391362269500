import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./store";

// @note: currently, it would probably be OK to just keep autoAxis, axisBoundsLeft and axisBoundsRight as
// a local state in ModuleChart.
export interface ChartsState {
  autoAxis              : {
    left                : boolean,
    right               : boolean,
  },
  axisBoundsLeft        : {
    min                 : number | undefined,
    max                 : number | undefined,
  },
  axisBoundsRight       : {
    min                 : number | undefined,
    max                 : number | undefined,
  },
  selectedTimestamp     : number | null
};

let initialState: ChartsState = {
  autoAxis              : {left: true, right: true},
  axisBoundsLeft        : {min: undefined, max: undefined},
  axisBoundsRight       : {min: undefined, max: undefined},
  selectedTimestamp     : null,
};

const chartsSlice = createSlice({
  name: 'charts',

  initialState,

  reducers: {
    setAutoAxis(state, action : PayloadAction<{left: boolean, right: boolean}>) {
      state.autoAxis = action.payload;
    },

    setAxisBoundsLeft(state, action: PayloadAction<{min: number | undefined, max: number | undefined}>) {
      state.axisBoundsLeft = action.payload;
    },

    setAxisBoundsRight(state, action: PayloadAction<{min: number | undefined, max: number | undefined}>) {
      state.axisBoundsRight = action.payload;
    },

    // set from ModuleTable, used in ModuleChart
    selectTimestamp(state, action : PayloadAction<number | null>) {
      state.selectedTimestamp = action.payload;
    },
  }
});

// ##### Selectors
export const getSelectedTimestamp = (state : RootState) => state.charts.selectedTimestamp;
export const getAutoAxis = (state : RootState) => state.charts.autoAxis;
export const getAxisBoundsLeft = (state : RootState) => state.charts.axisBoundsLeft;
export const getAxisBoundsRight = (state : RootState) => state.charts.axisBoundsRight;

// #### Default exports (actions, reducer)
export const {
  selectTimestamp,
  setAutoAxis,
  setAxisBoundsLeft,
  setAxisBoundsRight,
} = chartsSlice.actions;

export default chartsSlice.reducer;