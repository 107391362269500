import React from 'react';
import {
  IonIcon, IonItem, IonList,
  IonGrid, IonRow, IonCol, IonText, IonSpinner, IonButton, IonListHeader, IonLabel,
} from "@ionic/react";
import {Peripheral} from "../types";
import {analyticsOutline, wifiOutline, flashOutline, pulseOutline} from "ionicons/icons";
import {useBle} from "../hooks/useBle";
import {useAppSelector} from "../store/hooks";
import {getCurrentPeripheral} from "../store/peripheralsSlice";

type PeripheralsProps = {
  peripherals : Array<Peripheral>,
};

const Peripherals: React.FC<PeripheralsProps> = ({peripherals }) => {

  const {connectOrOpen, isConnecting, isConnectingTo, disconnect} = useBle();
  const currentPeripheral = useAppSelector(getCurrentPeripheral);

  return (
    <>
      {peripherals.length > 0 ? (
        <>
          <IonList lines="full" className="app-bg-neutral app-shadow-top">
            <IonListHeader>
              <IonLabel><h2>Devices</h2></IonLabel>
            </IonListHeader>

            {peripherals.map((peripheral, index: number) => (
              <IonItem key={index}
                       className={"ion-margin ion-no-padding app-device app-cursor-pointer"
                                  + ((isConnecting && isConnectingTo === peripheral.id) ? "app-device-connecting" : "")}
                       onClick={() => connectOrOpen(peripheral.id, peripheral.name as string)}
                       detail={false}>
                <IonGrid className="ion-no-padding">
                  <IonRow className="ion-padding-horizontal app-bg-highlight">
                    <IonCol className="ion-padding">
                      <div className="app-device-name">{peripheral.name}</div>
                      <IonRow className="ion-align-items-center">
                        <IonCol>
                          <div className="app-device-name-sub">{peripheral.id}</div>
                        </IonCol>
                        <IonCol className="ion-text-right">
                          {currentPeripheral.id === peripheral.id ?
                            (<IonButton color="main" size="small" className="app-device-action-button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          disconnect(peripheral.id);
                                        }}
                            >Disconnect</IonButton>) :
                            (<IonButton color="gray" size="small" className="app-device-action-button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          connectOrOpen(peripheral.id, peripheral.name as string);
                                        }}
                            >Connect</IonButton>)}
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  {peripheral.manufacturerData
                    ? (
                      <IonRow className="ion-padding app-device-details">
                        <>
                          <IonCol size="6" className="ion-padding app-device-detail">
                            <IonIcon icon={analyticsOutline} className="app-device-detail-icon" aria-hidden={true} />
                            <div className="app-device-detail-value app-device-detail-latest-value">
                              {peripheral.manufacturerData.value.toFixed(peripheral.manufacturerData.decimals)}&thinsp;<span className="app-device-detail-unit">{peripheral.manufacturerData.unit}</span>
                            </div>
                            <div className="app-device-detail-name app-device-detail-latest-value-name">
                              Latest Value
                            </div>
                          </IonCol>
                          <IonCol size="6" className="ion-padding app-device-detail">
                            <IonIcon icon={wifiOutline} className="app-device-detail-icon" aria-hidden={true} />
                            <div className="app-device-detail-value app-device-detail-latest-value">
                              {peripheral.rssi ? (<>{peripheral.rssi}dBm</>) : ''}
                            </div>
                            <div className="app-device-detail-name">
                              RSSI
                            </div>
                          </IonCol>
                          <IonCol size="6" className="ion-padding app-device-detail">
                            <IonIcon icon={flashOutline} className="app-device-detail-icon" aria-hidden={true} />
                            <div className="app-device-detail-value">
                              {peripheral.manufacturerData.voltage.toFixed(1)}&thinsp;<span className="app-device-detail-unit">V</span>
                            </div>
                            <div className="app-device-detail-name">
                              Voltage
                            </div>
                          </IonCol>
                          <IonCol size="6" className="ion-padding app-device-detail">
                            <IonIcon icon={pulseOutline} className="app-device-detail-icon" aria-hidden={true} />
                            <div className="app-device-detail-value">
                              {peripheral.manufacturerData.status}
                            </div>
                            <div className="app-device-detail-name">
                              Status
                            </div>
                          </IonCol>
                      </>
                    </IonRow>
                  ) : ''}
                </IonGrid>
                {isConnecting && isConnectingTo === peripheral.id ? (<>
                  <div className="app-device-connecting-overlay"></div>
                  <div className="app-device-connecting-spinner-wrapper">
                    <IonSpinner className="app-device-connecting-spinner"/>
                  </div>
                </>) : ""}
              </IonItem>
            ))}
          </IonList>
        </>

      ) : (
        <IonText className="ion-margin-start">
          <h3 className="app-no-device-text">Press Scan button to search for devices.</h3>
          <h4 className="app-no-device-text">Optionally, use prefix to filter devices by name.</h4>
        </IonText>)}
    </>
  );
};

export default Peripherals;
