import {
  IonContent,
  IonPage,
  IonToolbar, IonTitle, IonHeader,
  IonButton,
  IonButtons,
  IonList, IonItem, IonLabel,
  IonModal, IonIcon, IonAlert, IonText
} from '@ionic/react';
import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {useHistory} from "react-router-dom";
import {saveAs} from "file-saver";
import {Filesystem, Directory, Encoding} from '@capacitor/filesystem';
import {FileOpener} from "@capacitor-community/file-opener";
import {trash, open, mail, eye, save} from "ionicons/icons";

import './AppSettings.css';
import BackButton from "../components/BackButton";
import {appVersion, showDebugButton, privacyPolicyUrl, companyNamePart, appNamePart} from "../config";
import {isWeb} from "../utils/platforms";
import {useHwBack} from "../hooks/useHwBack";
import {useLog} from "../hooks/useLog";
import {deleteAllLogs, getLogs} from "../store/logSlice";
import {log} from "../utils/utils";
import {LogMsg} from "../types";

const AppSettings: React.FC = () => {
  const logTag = "AppSettings";

  const dispatch = useAppDispatch();
  const logs = useAppSelector(getLogs);
  const {logError} = useLog(logTag);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteLogAlert, setShowDeleteLogAlert] = useState(false);

  // handling the back button
  let history = useHistory();
  useHwBack(logTag, () => {
    if (showDeleteLogAlert) {
      setShowDeleteLogAlert(false);
    } else if (showModal) {
      setShowModal(false);
    } else {
      history.goBack();
    }
  }, [showDeleteLogAlert, showModal]);

  const logsToMailBody = (logs : Array<LogMsg>) => {
    return logs.reduce((result : string, logItem : LogMsg) => {
      return `${result} [${new Date(logItem.timestamp!).toLocaleString()}][${logItem.type}] ${logItem.msg} | %0D%0A`;
    }, "");
  };

  const logsToUtf8Text = (logs : Array<LogMsg>) => {
    return logs.reduce((result : string, logItem : LogMsg) => {
      return `${result}[${new Date(logItem.timestamp!).toLocaleString()}] [${logItem.type}] ${logItem.msg} \n\n`;
    }, "");
  }

  const saveLogs = async () => {
    const formattedLogs = logsToUtf8Text(logs);

    // on Web platform, we just use file-saver package
    if (isWeb()) {
      saveAs(
        new Blob([formattedLogs], {type: "text/plain;charset=utf-8"}),
        `${companyNamePart}_${appNamePart}_logs_${Date.now()}.txt`
      );

    // on iOS and Android, we have to use @capacitor/filesystem plugin and write to some predefined directory.
    // Since Android 13, it is not possible to access Documents directory. So let's store the file in Cache
    // directory and then immediately open it by using FileOpener plugin. This will allow the users to open
    // the file in an app of their choosing and save it where they want. Cache directory is cleaned by the
    // system when memory is low.
    } else {
      const writtenFile = await Filesystem.writeFile({
        path      : `${companyNamePart}_${appNamePart}_logs_${Date.now()}.txt`,
        data      : formattedLogs,
        directory : Directory.Cache,
        encoding  : Encoding.UTF8,
      });

      log.log(logTag, `File stored in: ${writtenFile.uri}`);

      await FileOpener.open({
        filePath    : writtenFile.uri,
        contentType : "text/plain"
      });
    }
  };

  const debug = async () => {
    logError("Debug button pressed", true);
  };

  return (
    <IonPage>
      <IonHeader className="app-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>
          <IonTitle>App Settings</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="app-layout">
        <IonList>
          
          <IonItem>
            <IonLabel>Show app logs</IonLabel>
            <IonButton color="main" size="default"
                       onClick={() => setShowModal(true)}
            >
              <IonIcon icon={eye} slot="start" aria-hidden={true}/>
              Display logs
            </IonButton>
          </IonItem>

          <IonItem>
            <IonLabel>Save logs to disk</IonLabel>
            <IonButton color="main" size="default"
                       onClick={() => saveLogs()}
            >
              <IonIcon icon={save} slot="start" aria-hidden={true} />
              Save
            </IonButton>
          </IonItem>

          <IonItem>
            <IonLabel>Send logs via email</IonLabel>
            <a href={"mailto:?subject=" + companyNamePart + "%20" + appNamePart + "%20App%20Logs&body=" + logsToMailBody(logs)}>
              <IonButton color="main" size="default">
                <IonIcon icon={mail} slot="start" aria-hidden={true} />
                Open email
              </IonButton>
            </a>
          </IonItem>

          <IonItem>
            <IonLabel>Delete all logs</IonLabel>
            <IonButton color="danger" size="default" onClick={() => setShowDeleteLogAlert(true)}>
              <IonIcon icon={trash} slot="start" aria-hidden={true} />
              Delete
            </IonButton>
          </IonItem>

          <IonItem>
            <IonLabel>Privacy policy</IonLabel>
            <IonButton color="main" size="default"
                       href={privacyPolicyUrl}
                       target="_blank"
            >
              <IonIcon icon={open} slot="start" aria-hidden={true} />
              Open
            </IonButton>
          </IonItem>

          <IonItem>
            <IonLabel>App version</IonLabel>
            <IonText slot="end">{appVersion}</IonText>
          </IonItem>

          {showDebugButton ? 
          (<IonItem>
            <IonLabel>Debug button</IonLabel>
            <IonButton color="main" size="default"
                       onClick={() => debug()}
            >
              Debug
            </IonButton>
          </IonItem>) : ""}

        </IonList>

        <IonModal isOpen={showModal}
                  backdropDismiss={true}
                  onDidDismiss={() => setShowModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Logs</IonTitle>
              <IonButtons slot="primary">
                <IonButton onClick={() => setShowModal(false)}
                           color="light"
                           fill="outline"
                >Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {logs.length === 0 ? <p className="ion-text-center"> No logs to display</p> : ""}
            <IonList className="">
              {[...logs].reverse().map((logMsg : LogMsg, index) => (
                <IonItem key={index} className="app-logs-item">
                  <IonLabel className="ion-text-wrap"
                            color={logMsg.type === "error" ? "danger" : ""}
                  >
                    {new Date(logMsg.timestamp!).toLocaleString()} | {logMsg.msg}
                  </IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        </IonModal>

        {/*Alert shown before deleting all logs*/}
        <IonAlert
          isOpen={showDeleteLogAlert}
          onDidDismiss={() => setShowDeleteLogAlert(false)}
          header={'Delete all logs?'}
          message={'This action is irreversible.'}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => { console.log('NOT deleting logs');}
            },
            {
              text: 'Delete',
              handler: () => { dispatch(deleteAllLogs()); }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default AppSettings;
