import {
  IonContent, IonGrid,
  IonPage, IonRow,
  IonCol, IonToolbar, IonTitle, IonHeader,
  IonButtons, IonText
} from '@ionic/react';
import find from "lodash/find";
import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../store/hooks";

import {useHwBack} from "../hooks/useHwBack";
import {getSelectedModules, isThereConnectedPeripheral} from "../store/peripheralsSlice";
import {ChartValue} from '../types';

import ModuleChart from "../components/ModuleChart";
import ModuleTable from "../components/ModuleTable";
import BackButton from "../components/BackButton";


const ModuleDetail: React.FC = () => {
  const logTag = "ModuleDetail";

  let history = useHistory();
  const isConnected = useAppSelector(isThereConnectedPeripheral);

  // conditional redirect to Main if no device is connected. Also works in case of disconnect
  useEffect(() => {
    if (!isConnected) {
      history.push('/main');
    }
  }, [isConnected, history]);

  // handling the back button
  useHwBack(logTag, null, []);

  // const isConnected = useAppSelector(isThereConnectedPeripheral);
  const selectedModules = useAppSelector(getSelectedModules);
  const moduleLeft = selectedModules[0];
  const moduleRight = selectedModules[1];

  const [chartValues, setChartValues] = useState<Array<ChartValue>>([]);

  useEffect(() => {

    let tempChartValues = new Array<ChartValue>();
    if (moduleLeft && moduleLeft.values) {
      tempChartValues = moduleLeft.values.map((left) => {

        let right = undefined;

        if (moduleRight && moduleRight.values) {
          right = find(moduleRight.values, (rightValue) => left.timestamp === rightValue.timestamp);
        }

        return {
          timestamp     : left.timestamp,
          left          : left.value,
          right         : right === undefined ? undefined : right.value
        }
      })
    }

    setChartValues(tempChartValues);

  }, [moduleLeft, moduleRight]);

  const contentElement = useRef<HTMLIonContentElement>(null);

  const scrollToTop = function() {
    if (contentElement.current !== null) {
      contentElement.current.scrollToTop(200);
    }
  }


  return (
    <IonPage>
      <IonHeader className="app-layout">
        <IonToolbar>
          <IonButtons slot="start">
            <BackButton />
          </IonButtons>
          <IonTitle>
            {moduleLeft ? moduleLeft.name : ''}
            {moduleRight ? ' + ' + moduleRight.name : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="app-layout" ref={contentElement}>
          {chartValues.length > 0 ? 
          (<IonGrid>
            <IonRow>
              <IonCol>
                <ModuleChart data={chartValues}
                            showRight={moduleRight ? true : false}

                            leftRange={moduleLeft ? {
                              min: moduleLeft.min ?? 0,
                              max: moduleLeft.max ?? 0
                            } : {min: 0, max: 0}}
                            leftDecimals={moduleLeft?.decimals ?? 0}
                            leftTitle={moduleLeft?.name ?? ""}
                            leftUnit={moduleLeft?.unit ?? ""}

                            rightRange={moduleRight ? {
                              min: moduleRight.min ?? 0,
                              max: moduleRight.max ?? 0
                            } : undefined}
                            rightDecimals={moduleRight?.decimals ?? 0}
                            rightTitle={moduleRight?.name ?? ""}
                            rightUnit={moduleRight?.unit ?? ""}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <ModuleTable data={chartValues}
                            scrollCallback={scrollToTop}
                            leftDecimals={moduleLeft?.decimals ?? 0}
                            rightDecimals={moduleRight?.decimals ?? 0}
                />
              </IonCol>
            </IonRow> 
          </IonGrid>) :
          (<IonText className="ion-text-center ion-padding">
            <h4 className="ion-padding" style={{ paddingLeft: 20, paddingRight: 20 }}>
              Waiting for the data from the device.
            </h4>
            <p className="ion-padding" style={{ paddingLeft: 20, paddingRight: 20 }}>
              Once the device sends the measurements, a graph will be displayed here.
            </p>
              
          </IonText>)}
        

      </IonContent>
    </IonPage>
  );
};

export default ModuleDetail;
