export const BluetoothStates : {[index: string] : string}  = {
  "unknown"       : "Bluetooth state is unknown.",
  "resetting"     : "Bluetooth is currently resetting.",
  "powered_off"   : "Please enable Bluetooth.",
  "powered_on"    : "Bluetooth is switched on and available for use.",
  "powering_off"  : "Bluetooth is currently switching off.",
  "powering_on"   : "Bluetooth is currently switching on.",
  "unauthorized"  : "Bluetooth use is not authorized for the current application."
};

export const BleErrorMessages : {[index: string] : string} = {
  "cordova_not_available"         : "The app doesn't run on mobile phone - Cordova not available!",
  "Bluetooth is disabled."        : "Please enable Bluetooth!",
  "User did not enable Bluetooth" : "Please enable Bluetooth!",
  "User cancelled the requestDevice() chooser." : "Device chooser cancelled by user",
  "Connection timeout"            : "Connection timeout!"
};

export const CharacteristicFormats : {[index: string] : number} = {
  "boolean"     : 1,
  "uint8"       : 4,
  "uint16"      : 6,
  "uint32"      : 8,
  "uint64"      : 10,
  "int8"        : 12,
  "int16"       : 14,
  "int32"       : 16,
  "int64"       : 18,
  "float32"     : 20,
  "float64"     : 21,
  "utf8string"  : 25,
  "utf16string" : 26,
};
