import {isAndroidNativeApp, isWeb, androidSDKVersion} from "../utils/platforms";
import {Diagnostic} from "@ionic-native/diagnostic";
import {BluetoothStates} from "../utils/bleDefinition";
import {log} from "../utils/utils";
import {useLog} from "./useLog";

// Diagnostic process that need to happen before we can interact with BLE
export function useDiagnostic() {
  const logTag = "useDiagnostic";
  const {logInfo} = useLog(logTag);

  // @note: BleClient.initialize checks for permissions, so this method might be unnecessary now.
  // But if the permissions are removed by Android automatically over time, initialization might fail.
  // See feature request:
  // https://github.com/capacitor-community/bluetooth-le/issues/445
  // https://github.com/capacitor-community/bluetooth-le/issues/487
  const diagnose = async () : Promise<boolean> => {
    log.log(logTag, "Diagnosing BT, BLE, location, permissions");

    if (isWeb()) {
      log.log(logTag, `Diagnose: platform is Web, returning true`);
      return true;
    }

    try {
      // Checks if Bluetooth is available to the app. Returns true if the device has Bluetooth capabilities
      // AND if Bluetooth setting is switched on (same on Android & iOS)/
      // Calling on iOS 13+ will request runtime permission to access Bluetooth (if not already requested).
      const available = await Diagnostic.isBluetoothAvailable();
      logInfo("Bluetooth is available: " + available);

      if (isAndroidNativeApp()) {
        // On Android 12+ / API 31+ (build SDK and device OS), run-time permission must be requested before
        // using Bluetooth APIs.
        // On iOS: maybe not needed? isBluetoothAvailable() asks for it, as well as other methods
        // @note: this throws an error while testing on Android 11 and 13, while the plugin example repo
        // does not. So for now, we rely more on BleClient.initialize().
        // Exception occurred: Value null at 0 of type org.json.JSONObject$1 cannot be converted to JSONArray
        // await Diagnostic.requestBluetoothAuthorization();
        // logInfo("Bluetooth authorization requested");

        const hasBleSupport = await Diagnostic.hasBluetoothLESupport();
        if (hasBleSupport) {
          logInfo("Your phone has BluetoothLE support.");
        } else {
          logInfo("Your phone doesn't support BluetoothLE!", true);
          return false;
        }

        // #### location stuff - this should be important only for API < 31 (Android <= 11). On 12+ location
        // access and location permissions are no longer required
        if (androidSDKVersion() < 31) {
          const locationAuthResult = await Diagnostic.requestLocationAuthorization();
          logInfo("Location authorization request result: " + locationAuthResult);

          const isLocationAvailable = await Diagnostic.isLocationAvailable();
          if (isLocationAvailable) {
            logInfo("App can access location of device.");
          } else {
            logInfo("App cannot access location.");
          }

          const isLocationEnabled = await Diagnostic.isLocationEnabled();
          if (isLocationEnabled) {
            logInfo("Location is enabled.", false);
          } else {
            logInfo("Please enable Location.", true);
            return false;
          }

          const locationMode = await Diagnostic.getLocationMode();
          logInfo("Location mode: " + locationMode);
        }

        // @note we cannot use BleClient.isEnabled() since it has not been initialized yet.
        // isBluetoothEnabled works only for Android, BleClient.isEnabled() for Android and iOS
        const isBtEnabled = await Diagnostic.isBluetoothEnabled();
        if (isBtEnabled) {
          logInfo("Bluetooth is enabled.", false);
        } else {
          logInfo("Please enable Bluetooth.", true);
          return false;
        }
      }

      // Android + iOS
      const btState = await Diagnostic.getBluetoothState();
      logInfo(`Bluetooth state: ${btState}`, false);
      if (btState === "powered_on" || btState === "unknown") {
        logInfo(BluetoothStates[btState], false);
      } else {
        logInfo(BluetoothStates[btState], true);
        return false;
      }

    } catch(error) {
      logInfo("Diagnostic error", true, error)
      return false;
    }

    return true;
  };

  return {
    diagnose
  };
}