import {Preferences} from "@capacitor/preferences";
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import remove from "lodash/remove";
import throttle from "lodash/throttle";
import {captureMessage, withScope} from '@sentry/browser';
import {LogMsg, Module, Peripheral, Setting} from "../types";
import {AppThunk, RootState} from "./store";
import {logRetentionDays, appVariant} from "../config";
import {log, stringifyObject, waitFor} from "../utils/utils";
import {BleErrorMessages} from "../utils/bleDefinition";
import {toastDefaultDurationMs} from "../config";
import {getCurrentPeripheral} from "./peripheralsSlice";

export interface LogState {
  logMsgs           : Array<LogMsg>,
  isToastShown      : boolean,
  toastMsg          : string
}

let initialState: LogState = {
  logMsgs               : new Array<LogMsg>(),
  isToastShown          : false,
  toastMsg              : ""
};

const logSlice = createSlice({
  name: 'log',

  initialState,

  reducers: {
    hydrateLog(state, action : PayloadAction<{log : Array<LogMsg>}>) {
      // console.log("hydrating logSlice:");
      // console.log(action.payload.log);
      state.logMsgs = action.payload.log;
    },

    addLogMsg(state, action : PayloadAction<LogMsg>) {
      state.logMsgs.push(action.payload);
    },

    clearLogMsgs(state) {
      state.logMsgs = new Array<LogMsg>();
    },

    showToast(state, action : PayloadAction<string>) {
      state.isToastShown = true;
      state.toastMsg = action.payload;
    },

    hideToast(state) {
      state.isToastShown = false;
      state.toastMsg = "";
    }
  }
});

// ##### Selectors
export const getLogs = (state : RootState) => state.log.logMsgs;

// ##### Thunks
// add log message to app's log
export const logMsgToAppLog = (type: string, msg: string): AppThunk =>
  async (dispatch, getState) => {

  // generate timestamp for this logItem
  const logItem = { type, msg, timestamp : Date.now()};

  // add the logItem to logMsgs in store
  dispatch(addLogMsg(logItem));

  // the logItem is already in the logMsgs in store, just take it and store it in Storage
  storeLog(getState().log.logMsgs);
};


let toastTimeoutId : ReturnType<typeof setTimeout> | null = null;
const throttledToast = throttle(async (dispatch : Function, msg : string, toastDuration : number) => {
    // remove previous toast
    if (toastTimeoutId) {
      clearTimeout(toastTimeoutId);
      toastTimeoutId = null;
    }
    dispatch(hideToast());
    // wait for render
    await waitFor(0);

    dispatch(showToast(msg));

    // automatic hiding of toast - we have to do this here and not in template. In template, previous toasts
    // couldn't be overwritten by next toasts
    toastTimeoutId = setTimeout(() => {
      dispatch(hideToast());
      toastTimeoutId = null;
    }, toastDuration);

  }, 100, {leading : false, trailing : true});

// display a toast and cancel the previous one, if shown. This has to be throttled, otherwise IonToast
// component seems to get stuck sometimes (toast showing forever)
export const toast = (msg : string, toastDuration : number = toastDefaultDurationMs) : AppThunk =>
  async (dispatch, getState) => {
    throttledToast(dispatch, msg, toastDuration);
};

// This accepts a string msg and an additional object and can write this to App's log, console, display a
// toast and capture a message for Sentry, while stringifying the object correctly.
// This is also used and simplified by useLog hook.
export const logToEverywhere = (
  logTag : string,
  msg : string,
  logType : string = "info",
  displayToast : boolean = false,
  objectToLog : any = null,
  toastDuration : number = toastDefaultDurationMs
) : AppThunk => async (dispatch, getState) => {

  if (msg) {
    // first, log our text message to console log
    log.log(logTag, msg);

    // then to app's log, accessible through Settings page
    dispatch(logMsgToAppLog(logType, msg));
  }

  // if there is any object present in payload, try to extract error message/msg/string from it
  let objectMessage = "";
  if (objectToLog) {
    const {objectMsg, objectRest} = stringifyObject(objectToLog);
    objectMessage = BleErrorMessages[objectMsg] ?? objectMsg;

    // log it to console and app's log as well
    if (objectMessage) {
      log.log(logTag, objectMessage);
      dispatch(logMsgToAppLog(logType, objectMessage));
    }

    // if the error was a combined Error with other fields, or just the other fields, let's also log them
    if (objectRest) {
      log.log(logTag, objectRest);
      dispatch(logMsgToAppLog(logType, objectRest));
    }
  }

  // report to Sentry. If there is a current peripheral, add its data. If there is an object to log, send it
  // to sentry as well
  if (logType === "error") {
    withScope(function(scope) {
      // we log the whole objectToLog, i.e. any Error and any additional fields
      scope.setContext("extra_data", objectToLog);

      // process current peripheral to some short summary suitable for Sentry
      const currentPeripheral = getCurrentPeripheral(getState());
      if (currentPeripheral && currentPeripheral.id) {
        scope.setContext("peripheral", peripheralToSentryContext(currentPeripheral));
      }

      // add app variant so that it's immediately visible in sentry events UI
      scope.setContext("app_variant", { variant : appVariant });

      log.log(logTag, `Sending to Sentry: ${msg}`);
      captureMessage(msg);
    });
  }

  // finally, display either the message extracted from payload's object, or from the text message
  if (displayToast) {
    dispatch(toast((objectMessage || msg), toastDuration));
  }
}

// a helper function to extract important information from peripheral for the purpose of Sentry logs
export const peripheralToSentryContext = (peripheral : Peripheral) => {
  const { name, id, mtu, otaSupport, uartSupport, modules, settings } = peripheral;
  const moduleNames = modules?.map((module : Module) => `${module.name} (${module.id})` );
  const settingsNames = settings?.map((setting : Setting) => `${setting.name} (${setting.id})`);
  return {name, id, mtu, otaSupport, uartSupport, modules : moduleNames, settings: settingsNames};
}

// this is called on app start. Steps:
// - pull logs from Storage
// - remove old logs and re-save to Storage
// - hydrate the store
export const initLogStore = () : AppThunk => async dispatch => {
  console.log("[logSlice] init");

  let log = await getLog();

  // removing messages older than a specific time
  if (log.length > 0) {
    // "remove" mutates log and returns removed items
    const removed = remove(log, (logItem : LogMsg) => {
      return logItem.timestamp! < (Date.now() - logRetentionDays*24*60*60*1000);
    });

    // re-save the log without the old messages
    if (removed.length > 0) {
      console.log("[logSlice] Removing old messages: ");
      console.log(removed);
      storeLog(log);
    }
  }

  await dispatch(hydrateLog({log}));
};

export const deleteAllLogs = () : AppThunk => async dispatch => {
  console.log("[logSlice] deleting all log messages");
  await storeLog(new Array<LogMsg>());
  dispatch(clearLogMsgs());
  dispatch(logMsgToAppLog("info", "Log messages cleared manually"));
};

// #### helpers, not part of Redux
const storeLog = async (log : Array<LogMsg>) => {
  await Preferences.set({key: `log`, value: JSON.stringify(log)});
};

const getLog = async () => {
  const { value } = await Preferences.get({key : "log"});
  return value ? JSON.parse(value) : [];
};

// #### Default exports (actions, reducer)
export const {
  hydrateLog,
  addLogMsg,
  clearLogMsgs,
  showToast,
  hideToast
} = logSlice.actions;

export default logSlice.reducer;
