import { combineReducers } from '@reduxjs/toolkit';
import peripheralsReducer from "./peripheralsSlice";
import chartsReducer from "./chartsSlice";
import logReducer from "./logSlice";
import writeProgressReducer from "./writeProgressSlice";

const rootReducer = combineReducers({
  peripherals           : peripheralsReducer,
  charts                : chartsReducer,
  log                   : logReducer,
  writeProgress         : writeProgressReducer
});

export default rootReducer;
