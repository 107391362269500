import {BleService, BleCharacteristicProperties, BleDescriptor} from "@capacitor-community/bluetooth-le";

// @note when importing enums from this file, it must be imported as "types.d", not "types", otherwise you'll
// get weird "can't find module" errors
export enum SettingTypes { number, string, char, boolean }
export enum GraphTypes { oneAxis, twoAxis, histogram}

// this is our own format we use throughout the app - for scanned and connected peripherals
export interface Peripheral {
  id                      : string,
  name?                   : string,
  rssi?                   : number,
  mtu?                    : number,

  manufacturerData?       : ManufacturerData,

  services?               : Array<BleService>,

  modules?                : Array<Module>,
  settings?               : Array<Setting>,
  otaSupport?             : boolean,
  uartSupport?            : boolean
  uartNotifying?          : boolean,
  uartLog?                : Array<UartLogMsg>,

  pinEntered?             : boolean,
}

export interface ManufacturerData {
  value           : number,
  decimals        : number,
  unit            : string,
  status          : string,
  voltage         : number
}

export interface ParsedDescriptors {
  name            : string,
  icon            : string,
  unit            : string | null,
  min             : number | null,
  max             : number | null,
  decimals        : number,
  exponent        : number,
  format          : number,
}

export interface ModuleBase {
  id              : string,
  serviceId       : string,
  properties      : BleCharacteristicProperties;
  descriptors     : Array<BleDescriptor>,
  descriptorsRead : boolean,

  name            : string,
  icon            : string,
  unit            : string | null,
  min             : number | null,
  max             : number | null,
  decimals        : number,
  exponent        : number,
  format          : number,

  value?          : number | string,  // @note: can it actually be string for Modules?
}

export interface Module extends ModuleBase {
  graph?          : GraphTypes,
  values?         : Array<ModuleValue>
}

export interface Setting extends ModuleBase {
  type            : SettingTypes,
  writable        : boolean,
  info            : string
}

export interface ModuleValue {
  timestamp       : number,
  value           : number
}

export interface ChartValue {
  timestamp       : number,
  left            : number,
  right?          : number,
}

export interface LogMsg {
  timestamp?  : number,
  type        : string,
  msg         : string
}

// basically nothing else to add currently
export interface UartLogMsg extends LogMsg {}

// for errors thrown during connecting to a peripheral, so that we can process them in one catch()
export class PeripheralConnectionError extends Error {
  extraData : Object | undefined;

  constructor(message : string, extraData? : Object) {
    super(message);
    this.extraData = extraData;
  }
}