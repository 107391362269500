import {toastDefaultDurationMs} from "../config";
import {logToEverywhere} from "../store/logSlice";
import {useAppDispatch} from "../store/hooks";

// Logging hook just for convenience.
// This can log into console, App log and also show a toast if needed
export function useLog(logTag : string) {

  const dispatch = useAppDispatch();

  const logAll = (
    msg : string, logType : string = "info", displayToast : boolean = false, obj : any = null,
    toastDuration : number = toastDefaultDurationMs
  ) => {
    dispatch(logToEverywhere(logTag, msg, logType, displayToast, obj, toastDuration));
  }

  const logInfo = (msg : string, displayToast : boolean = false, obj : any = null) => {
    logAll(msg, "info", displayToast, obj);
  };

  const logError = (msg : string, displayToast : boolean = false, obj : any = null) => {
    logAll(msg, "error", displayToast, obj);
  };

  return {
    logInfo,
    logError,
    logAll
  }
}
