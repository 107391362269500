import React from 'react';
import {
  IonCard,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import {useHistory} from "react-router-dom";

type StaticCardProps = {
  name        : string,
  icon        : string,
  href        : string,
};

const StaticCard: React.FC<StaticCardProps> = (
  {name, icon, href }
) => {

  let history = useHistory();

  // This was just too long to keep it inline.
  let cardClasses = 'ion-text-center ion-padding ion-justify-content-center ion-align-items-center ' +
    'app-module-card app-cursor-pointer';

  return (
      <IonCard className={cardClasses}
               onClick={() => history.push(href)}
      >
        <div className="app-module-card-icon">
          <IonIcon src={"assets/svg/" + icon + ".svg"} size="large" aria-hidden={true} />
        </div>

        <IonCardTitle>
          {name}
        </IonCardTitle>
      </IonCard>
  );
};

export default StaticCard;
