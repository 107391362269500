import {useIonViewWillEnter} from '@ionic/react';
import {App} from "@capacitor/app";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {log} from "../utils/utils";

export function useHwBack(
  logTag : string, callback? : null | (() => void), dependencies : Array<any> = []
) {
  let history = useHistory();

  const handleBack = () => {
    log.log(logTag, 'Hardware back button pressed');
    if (typeof callback === "function") {
      callback();
    } else {
      history.goBack();
    }
  }

  // We need this hook because we can't rely solely on React hooks. For example, leaving Main and returning
  // to Main, the useEffect will not be called again - no idea why. These ionic hooks do get called every
  // time, but sometimes they have problems with stale state.
  // Problem with this is that when the component is entered, this hook will try to add the listener twice.
  // Thus we have to removeAllListeners both here and in useEffect hook below.
  // @note: notice "dependencies" - an undocumented feature of ionic lifecycle hooks. Sometimes it seems to
  // work, sometimes not, but it's needed here
  useIonViewWillEnter(() => {
    // log.log(logTag, "Back button: useIonViewWillEnter");
    // we could remember the listener handle returned by addListener in local state and remove only that,
    // but then we'd have problem with stale state either in this hook or in useEffect later
    App.removeAllListeners();
    App.addListener("backButton", handleBack);
  }, dependencies);

  // This effect is called every time dependencies change, i.e. for example when a modal is opened/closed
  useEffect(() => {
    // log.log(logTag, "Back button: useEffect with dependencies. Removing all + adding");
    // First, remove all listeners. We do not use useEffect cleanup here because I guess it doesn't matter
    App.removeAllListeners();
    App.addListener("backButton", handleBack);
  }, dependencies);
}