// #######################################
// ### Conversions - HEX, ascii string, ArrayBuffer, Typed Arrays
// #######################################
// useful article on typed arrays and DataView: https://www.html5rocks.com/en/tutorials/webgl/typed_arrays/

// i must be < 256
export const byteToHexString = (i : number) : string => {
  var hex;

  hex = i.toString(16).toUpperCase();

  // zero padding
  if (hex.length === 1) {
    hex = "0" + hex;
  }

  return hex;
  // return "0x" + hex;
};

export const uint8ArrayToHexString = (data : Uint8Array) : string => {
  let hexString = "0x";

  data.forEach(byte => {
    hexString += byteToHexString(byte);
  });

  return hexString;
};

// parse tuples of bytes to UUID hex strings - little endian!
export const uuid16ToHexStrings = (bytes : Uint8Array) : string => {
  let i : number = 0;
  let resultArray = new Array<string>();

  while (i < bytes.length) {
    resultArray.push("0x" + byteToHexString(bytes[i + 1]) + byteToHexString(bytes[i]));
    i = i + 2;
  }

  return resultArray.join(", ");
};

// invalid inputs like "xx" result in ArrayBuffer [0] which is fine I guess
// @note: there should be some safeguards or info back to user
export const hexStringToBytes = (hexString : string) : ArrayBuffer => {
  let result = [];
  for (let i = 0; i < hexString.length; i += 2) {
    result.push(parseInt(hexString.substr(i, 2), 16));
  }
  return new Uint8Array(result).buffer;
};

// ASCII only
export const utf8StringToBytes = (str : string) : ArrayBuffer => {
  let array = new Uint8Array(str.length);
  for (let i = 0, l = str.length; i < l; i++) {
    array[i] = str.charCodeAt(i);
  }
  return array.buffer;
};

export const boolToBytes = (value : boolean | string | number) : ArrayBuffer | null => {
  if (value === true || value === "1" || value === 1) {
    return Uint8Array.from([1]).buffer;
  }
  if (value === false || value === "0" || value === 0) {
    return Uint8Array.from([0]).buffer;
  }
  return null;
};

// this converts a string to a number before sending it for a write. Exponent is important - the device will
// simply take the final value and write it to a characeristic without any further parsing. We have to make
// sure that when we read the value again, we can reconstruct it correctly, using the Format descriptor
// containing exponent. So for example, if we have a number 125.8 and exponent is -1, we write it as 1258 and
// read it as 1258 * 10^-1. If we have 125.8 and exponent 1, we write it as 13 (precision is lost) and read
// it as 13 * 10^1 = 130.
export const stringToIntWithExponent = (value : string, exponent : number) : number | null => {
  let parsedNumber = Number(value);
  if (!isFinite(parsedNumber)) {
    return null;
  }

  // @note we use -exponent so that it is written to peripheral in a correct way - when it is read again, the
  // exponent is used again to create the final value.
  // This also means numbers with positive exponent will lose precision
  parsedNumber = Math.round(parsedNumber * Math.pow(10, -exponent));
  return parsedNumber;
};

export const stringToFloat = (value : string) : number | null => {
  let parsedNumber = Number(value);
  if (!isFinite(parsedNumber)) {
    return null;
  }
  return parsedNumber;
};

export const uint8ToBytes = (value : number) : ArrayBuffer => {
  return Uint8Array.from([value]).buffer;
};

export const uint16ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Uint16Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setUint16(0, value, littleEndian);
  return data.buffer;
};

export const uint32ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Uint32Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setUint32(0, value, littleEndian);
  return data.buffer;
};

export const int8ToBytes = (value : number) : ArrayBuffer => {
  return Int8Array.from([value]).buffer;
};

export const int16ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Int16Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setInt16(0, value, littleEndian);
  return data.buffer;
};

export const int32ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Int32Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setInt32(0, value, littleEndian);
  return data.buffer;
};

export const float32ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Float32Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setFloat32(0, value, littleEndian);
  return data.buffer;
};

export const float64ToBytes = (value : number, littleEndian : boolean = true) : ArrayBuffer => {
  const buffer = new ArrayBuffer(Float64Array.BYTES_PER_ELEMENT);
  const data = new DataView(buffer);
  data.setFloat64(0, value, littleEndian);
  return data.buffer;
};

// ASCII only
export const bytesToUtf8String = (buffer : ArrayBuffer) : string => {
  // @ts-ignore
  return String.fromCharCode.apply(null, new Uint8Array(buffer));
};

export const hexStringToUtf8String = (hexString : string) : string => {
  var str = '';
  for (var i = 0; (i < hexString.length && hexString.substr(i, 2) !== '00'); i += 2)
    str += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
  return str;
}

export const bytesToUint8Array = (buffer : ArrayBuffer) : Uint8Array => {
  return new Uint8Array(buffer);
};

export const bytesToUint16Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Uint16Array => {
  const data = new DataView(buffer);
  let resultArray = new Uint16Array(data.byteLength / Uint16Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getUint16(i * Uint16Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

export const bytesToUint32Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Uint32Array => {
  const data = new DataView(buffer);
  let resultArray = new Uint32Array(data.byteLength / Uint32Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getUint32(i * Uint32Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

// @note this creates problems with bigint format
export const bytesToUint64Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : BigUint64Array => {
  const data = new DataView(buffer);
  let resultArray = new BigUint64Array(data.byteLength / BigUint64Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getBigUint64(i * BigUint64Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

export const bytesToInt8Array = (buffer : ArrayBuffer) : Int8Array => {
  return new Int8Array(buffer);
};

export const bytesToInt16Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Int16Array => {
  const data = new DataView(buffer);
  let resultArray = new Int16Array(data.byteLength / Int16Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getInt16(i * Int16Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

export const bytesToInt32Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Int32Array => {
  const data = new DataView(buffer);
  let resultArray = new Int32Array(data.byteLength / Int32Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getInt32(i * Int32Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

// @note this creates problems with bigint format
export const bytesToInt64Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : BigInt64Array => {
  const data = new DataView(buffer);
  let resultArray = new BigInt64Array(data.byteLength / BigInt64Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getBigInt64(i * BigInt64Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};


export const bytesToFloat32Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Float32Array => {
  const data = new DataView(buffer);
  let resultArray = new Float32Array(data.byteLength / Float32Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getFloat32(i * Float32Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

export const bytesToFloat64Array = (buffer : ArrayBuffer, littleEndian : boolean = true) : Float64Array => {
  const data = new DataView(buffer);
  let resultArray = new Float64Array(data.byteLength / Float64Array.BYTES_PER_ELEMENT);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data.getFloat64(i * Float64Array.BYTES_PER_ELEMENT, littleEndian);
  }

  return resultArray;
};

// according to BLE spec:
// If a format is not a whole number of octets, then the data shall be contained within the least significant
// bits of the value, and all other bits shall be set to zero on transmission and ignored upon receipt. If
// the Characteristic Value is less than an octet, it occupies an entire octet.
// @note that we actually use 0 and 1 instead of false and true!
export const bytesToBoolArray = (buffer : ArrayBuffer) : Array<number> => {
  const data = new Uint8Array(buffer);
  let resultArray = new Array<number>(data.length);
  const length = resultArray.length;

  for (let i = 0; i < length; ++i) {
    resultArray[i] = data[i] & 1;
  }

  return resultArray;
};
