import {useState} from "react";
import {maxStringWriteLength} from "../config";
import {log} from "../utils/utils";

// Various form input validations
export function useValidations() {
  const logTag = "useValidations";

  const [validationMessage, setValidationMessage] = useState<string>("");

  const clearValidationMessage = () => {
    setValidationMessage("");
  }

  const validateBoolean = (newValue : string) : boolean => {
    if (newValue !== "0" && newValue !== "1") {
      setValidationMessage(`Something went wrong - please try flipping the switch again.`);
      return false;
    }
    return true;
  };


  const validateString = (newValue : string) : boolean => {
    if (newValue.length > maxStringWriteLength) {
      setValidationMessage(`The string cannot be longer than ${maxStringWriteLength} characters.`);
      return false;
    }
    return true;
  };

  const validateNumber = (newValue : string, min : (number | null) = null, max : (number | null) = null)
    : boolean => {

    const newValueParsed = Number(newValue);

    if (!isFinite(newValueParsed)) {
      setValidationMessage("This is not a valid number.");
      return false;
    }

    if (min !== null && newValueParsed < min) {
      log.log(logTag, `Value ${newValueParsed} is less than ${min}, validation failed`);
      setValidationMessage(`Value cannot be less than ${min}.`);
      return false;
    }

    if (max !== null && newValueParsed > max) {
      log.log(logTag, `Value ${newValueParsed} is greater than max ${max}, validation failed`);
      setValidationMessage(`Value cannot be greater than ${max}.`);
      return false;
    }

    // validation passed
    return true;
  };

  return {
    validationMessage,
    setValidationMessage,
    clearValidationMessage,
    validateBoolean,
    validateNumber,
    validateString
  }
}