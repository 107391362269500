import React, {useEffect, useState} from 'react';
import isEqual from 'lodash/isEqual';

import {
  ResponsiveContainer,
  LineChart, Line,
  XAxis, YAxis, Label,
  CartesianGrid, Tooltip,
} from 'recharts';

import {ChartValue} from "../types";
import {chartStepsCount} from "../config";
import {IonRow, IonButton, IonCol, IonIcon} from "@ionic/react";
import {addOutline, removeOutline} from "ionicons/icons";
import {
  getSelectedTimestamp, selectTimestamp,
  getAutoAxis, setAutoAxis,
  getAxisBoundsLeft, setAxisBoundsLeft,
  getAxisBoundsRight, setAxisBoundsRight
} from "../store/chartsSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";

type AxisRange = {
  min           : number,
  max           : number,
}

type ModuleChartProps = {
  data          : Array<ChartValue>,
  showRight     : boolean,

  leftRange     : AxisRange,
  leftDecimals  : number,
  leftTitle     : string,
  leftUnit      : string,

  rightRange?   : AxisRange,
  rightDecimals : number,
  rightTitle    : string,
  rightUnit     : string,
};

const ModuleChart: React.FC<ModuleChartProps> = (
  { data, showRight,
    leftRange, leftDecimals, leftTitle, leftUnit, 
    rightRange, rightDecimals, rightTitle, rightUnit }
) => {

  const dispatch = useAppDispatch();

  const autoAxis = useAppSelector(getAutoAxis);
  const axisBoundsLeft = useAppSelector(getAxisBoundsLeft);
  const axisBoundsRight = useAppSelector(getAxisBoundsRight);

  // We'll zoom in/out by 1/zoomPart of current min/max
  const zoomPart = 8;

  const selectedTimestamp = useAppSelector(getSelectedTimestamp);

  const [shownData, setShownData] = useState<Array<ChartValue>>([]);


  // initial effect - reset everything
  useEffect(() => {
    return () => {
      dispatch(setAxisBoundsLeft({min: undefined, max: undefined}));
      dispatch(setAxisBoundsRight({min: undefined, max: undefined}));
      dispatch(setAutoAxis({left: true, right: true}));
      dispatch(selectTimestamp(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  // @note: currently, this whole mechanic works only when there is enough data in the `values` of the 
  // module. Before that, clicking on a row in the table just resets the graph. Which is kinda unintuitive.
  useEffect(() => {

    // Graph can be fixed on some timestamp. This timestamp should be in the half of the X axis.
    if (selectedTimestamp !== null) {

      // Find the index of this timestamp in current data set
      const sliceStartIndex = data.findIndex((item) => item.timestamp === selectedTimestamp);

      // How many steps do we want to show on each side of timestamp?
      const halfOfSteps = chartStepsCount / 2;

      // If the selected timestamp is above the highest or below the lowest value in the current dataset
      // (with the offset of half steps count), we will reset the timestamp selection.
      if (sliceStartIndex + halfOfSteps >= data.length || sliceStartIndex - halfOfSteps < 0) {

        // Reset timestamp
        dispatch(selectTimestamp(null));
        // Reset zoom
        dispatch(setAutoAxis({left: true, right: true}));
        dispatch(setAxisBoundsLeft({min: undefined, max: undefined}));
        dispatch(setAxisBoundsRight({min: undefined, max: undefined}));

      } else {

        // Otherwise slice the data
        setShownData(data.slice(sliceStartIndex - halfOfSteps, sliceStartIndex + halfOfSteps));
      }
    } else {

      // We don't have any timestamp selected, just take the last values
      setShownData(data.slice(chartStepsCount * -1));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimestamp, data]);

  // auto axis - recomputing the bounds for left and right axis with changing data
  // @todo: add some +-10% if min === max
  useEffect(() => {
    // copy the current bounds so that we can change it in the forEach loop which follows
    let newBoundsLeft = {...axisBoundsLeft};
    let newBoundsRight = {...axisBoundsRight};

    // go through all the data currently shown in the graph and find min/max bounds for left and right axis
    shownData.forEach(item => {
      // if left axis is in "auto" mode, we need to go through the whole data shown and find the axis bounds
      if (autoAxis.left) {
        if (newBoundsLeft.min === undefined || item.left < newBoundsLeft.min) {
          newBoundsLeft.min = item.left;
        }

        if (newBoundsLeft.max === undefined || item.left > newBoundsLeft.max) {
          newBoundsLeft.max = item.left;
        }
      }

      // if right axis is in "auto" mode, we need to go through the whole data shown and find the axis bounds
      if (item.right && autoAxis.right) {
        if (newBoundsRight.min === undefined || item.right < newBoundsRight.min) {
          newBoundsRight.min = item.right;
        }

        if (newBoundsRight.max === undefined || item.right > newBoundsRight.max) {
          newBoundsRight.max = item.right;
        }
      }
    });

    // we only dispatch once per this useEffect and only if the bounds actually changed
    if (!isEqual(axisBoundsLeft, newBoundsLeft) 
      && newBoundsLeft.max !== undefined && newBoundsLeft.min !== undefined
    ) {
      // +-10% of the value to create some range even if min = max and to have better top/bottom of the graph
      const range = Math.abs(newBoundsLeft.max - newBoundsLeft.min);
      const boundsExtension = range > 0 ? 0.1 * range : 0.1 * Math.abs(newBoundsLeft.max);

      dispatch(setAxisBoundsLeft({
        min : newBoundsLeft.min - boundsExtension,
        max : newBoundsLeft.max + boundsExtension
      }));
    }

    if (!isEqual(axisBoundsRight, newBoundsRight)
      && newBoundsRight.max !== undefined && newBoundsRight.min !== undefined
    ) {
      // +-10% of the value to create some range even if min = max and to have better top/bottom of the graph
      const range = Math.abs(newBoundsRight.max - newBoundsRight.min);
      const boundsExtension = range > 0 ? 0.1 * range : 0.1 * Math.abs(newBoundsRight.max);

      dispatch(setAxisBoundsRight({
        min : newBoundsRight.min - boundsExtension,
        max : newBoundsRight.max + boundsExtension
      }));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownData, autoAxis]);


  function zoomDelta(min: number, max: number, direction: number) {
    return ((max - min) / zoomPart) * direction;
  }

  function zoom(direction: number, axis: string) {

    if (axis === "left") {
      let delta = zoomDelta(axisBoundsLeft.min ?? 0, axisBoundsLeft.max ?? 0, direction);

      dispatch(setAxisBoundsLeft({
        min: (axisBoundsLeft.min ?? 0) + delta,
        max: (axisBoundsLeft.max ?? 0) - delta
      }));

      // turn-off auto bounding for left axis
      if (autoAxis.left) {
        dispatch(setAutoAxis({left: false, right: autoAxis.right}));
      }

    } else if (axis === "right") {
      let delta = zoomDelta(axisBoundsRight.min ?? 0, axisBoundsRight.max ?? 0, direction);

      dispatch(setAxisBoundsRight({
        min: (axisBoundsRight.min ?? 0) + delta,
        max: (axisBoundsRight.max ?? 0) - delta
      }));

      // turn off auto bounding for right axis
      if (autoAxis.right) {
        dispatch(setAutoAxis({left: autoAxis.left, right: false}));
      }
    }
  }


  return (

    <div>
      <IonRow>
        <ResponsiveContainer height="50%"
                             minHeight={320}
                             width="100%"
        >
          <LineChart data={shownData}
                     margin={{top: 10, bottom: 10, left: 15, right: 15 }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            
            <XAxis dataKey="timestamp"
                   axisLine={true}
                   padding={{ left: 0, right: 0 }}
                   tick={{ fontSize: 14 }}
                   interval="preserveStartEnd"
            >
                  <Label value="step"
                         offset={-7}
                         position="bottom"
                         style={{ fontSize: '12px' }}
                  />
            </XAxis>

            <YAxis domain={[axisBoundsLeft.min ?? leftRange.min, axisBoundsLeft.max ?? leftRange.max]}
                   allowDataOverflow={true}
                   mirror={true}
                   type="number"
                   padding={{ top: 0, bottom: 10 }}
                   tickFormatter={tick => tick.toFixed(leftDecimals)}
                   tick={{ fill: '#8884d8', fontSize: 12, fontWeight: 400 }}
                   stroke="#8884d8"
                   yAxisId="left"
                   interval={0}
                   scale="linear"
                   tickCount={8}
            >
              <Label value={`${leftTitle} [${leftUnit}]`}
                     offset={10}
                     angle={-90}
                     position="left"
                     style={{ fontSize: '12px', fontWeight: 600, textAnchor: 'middle', fill: "#8884d8" }}
              />
            </YAxis>
            { (showRight && rightRange) ? (
              <YAxis domain={[axisBoundsRight.min ?? rightRange.min, axisBoundsRight.max ?? rightRange.max]}
                     allowDataOverflow={true}
                     mirror={true}
                     type="number"
                     padding={{ top: 0, bottom: 10 }}
                     tickFormatter={tick => tick.toFixed(rightDecimals)}
                     tick={{ fill: '#e03838', fontSize: 12, fontWeight: 400 }}
                     allowDecimals={true}
                     stroke="#e03838"
                     yAxisId="right"
                     interval={0}
                     scale="linear"
                     tickCount={8}
                     orientation="right"
              >
                <Label value={`${rightTitle} [${rightUnit}]`}
                       offset={10}
                       angle={-90}
                       position="right"
                       style={{ fontSize: '12px', fontWeight: 600, textAnchor: 'middle', fill: "#e03838" }}
                />
              </YAxis>
            ) : ''}
            <Line type="monotone"
                  yAxisId="left"
                  dataKey="left"
                  stroke="#8884d8"
                  activeDot={{ r: 6 }}
                  isAnimationActive={false} />
            { (showRight && rightRange) ? (
            <Line type="monotone"
                  yAxisId="right"
                  dataKey="right"
                  stroke="#e03838"
                  activeDot={{ r: 6 }}
                  isAnimationActive={false} />
            ) : ''}
            <Tooltip formatter={(value, name, props) => {
                       // @note: we don't display the "name" here, just the values 
                       return[`${value} ${(props.name === "left") ? leftUnit : rightUnit}`, null]
                     }}
                     labelFormatter={(label) => ""}
                     itemStyle={{ fontSize: 12, padding: 0, fontWeight: 600 }}
                     contentStyle={{ padding: 5 }}
                     cursor={{ strokeWidth: 1, stroke: '#454545' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </IonRow>

      <IonRow class="app-chart-axis-controls">
        <IonCol size="6">
          <IonButton onClick={() => zoom(-1, "left")} color="main">
            <IonIcon icon={removeOutline} aria-label="Zoom out" />
          </IonButton>
          <IonButton onClick={() => zoom(1, "left")} color="main">
            <IonIcon icon={addOutline} aria-label="Zoom in"/>
          </IonButton>
        </IonCol>
        {showRight ? (
          <IonCol size="6" class="ion-text-right">
            <IonButton onClick={() => zoom(-1, "right")} color="main">
              <IonIcon icon={removeOutline} aria-label="Zoom out"/>
            </IonButton>
            <IonButton onClick={() => zoom(1, "right")} color="main">
              <IonIcon icon={addOutline} aria-label="Zoom in"/>
            </IonButton>
          </IonCol>
        ): ''}

      </IonRow>
    </div>
  );
};

export default ModuleChart;
